const vars = {
  fontFamily: 'Inter, sans-serif',
  primaryColor: '#3779E1',
  primaryColorHover: '#2763C1',
  outlinedButtonHover: '#E4EEFF',
  secondaryColor: '#222739',
  searchBg: 'rgba(255, 255, 255, 0.1)',
  primaryBgColor: '#F5F7FB',
  primaryTextColor: '#2E3A59',
  lightBorderColor: 'rgba(255, 255, 255, 0.1)',
  noInstanceColor: 'rgba(255, 255, 255, 0.6)',
  inputTextColor: 'rgba(255, 255, 255, 0.8)',
  iconButtonHover: 'rgba(255, 255, 255, 0.2)',
  radius: '.5rem',
  gutter: 16,
  whiteColor: '#FFFFFF',
  sidebarIconColor: 'rgba(221, 221, 221, 0.8)',
  primaryTransition: 'all ease-in-out .3s',
  errorColor: '#ED745D',
  progressErrorColor: 'rgba(237, 116, 93, 0.1)',
  barSuccessColor: '#6FC386',
  tabsBgColor: '#F5F8FD',
  progressBgPrimary: 'rgba(55, 121, 225, 0.1)',
  dropzoneBorderColor: 'rgba(55, 121, 225, 0.3)',
  dropzoneTextColor: 'rgba(46, 58, 89, 0.3)',
  inputFocusShadow: 'rgba(55, 121, 225, 0.1)',
  successInputFocusShadow: 'rgba(111, 195, 134, 0.1)',
  placeHolderColor: 'rgba(46, 58, 89, 0.6)',
  dialogShadow: 'rgba(0, 0, 0, 0.1)',
  tabsBorderColor: 'rgba(0, 0, 0, 0.1)',
  dialogBodyBgColor: 'rgba(55, 121, 225, 0.05)',
  sideBarTextColor: 'rgba(46, 58, 89, 0.8)',
  chipBgColor: 'rgba(46, 58, 89, 0.05)',
  dataset: '#3779E1',
  matlab: '#6FC386',
  nifti: '#7747F6',
  volume: '#3779E1',
  sideBarLabelColor: '#435070',
  treeBorderColor: '#4E5261',
  scrollbarBg: 'rgba(0, 0, 0, 0.24)',
  gray800: '#0F162B',
  gray400: '#586482',
  gray25: '#F0F1F2',
  grey700: '#212B45',
  grey500: '#435070',
  grey100: '#C9CDD6',
  grey400: '#586482',
  grey50: '#E1E3E8',
  grey25: '#F0F1F2',
  grey600: '#2E3A59',
};

export default vars;
