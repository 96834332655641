import {SvgIcon} from "@material-ui/core";
export const DatasetIcon = (props) => (
    <SvgIcon width="48" height="48" viewBox="0 0 48 48" fill="none" {...props}>
        <rect width="48" height="48" rx="24" fill="#3779E1" fill-opacity="0.1"/>
        <path d="M15.3086 16.1797C15.3086 18.0664 18.6133 19.3906 23.5 19.3906C28.3867 19.3906 31.6914 18.0664 31.6914 16.1797C31.6914 14.2109 28.4102 12.8398 23.5 12.8398C18.5898 12.8398 15.3086 14.2109 15.3086 16.1797ZM23.5 26.6445C19.1523 26.6445 15.8945 25.6602 13.4219 23.5625V16.1797C13.4219 13.2266 17.4531 11.1875 23.5 11.1875C29.5469 11.1875 33.5781 13.2266 33.5781 16.1797V23.5625C31.1055 25.6602 27.8477 26.6445 23.5 26.6445ZM13.4219 30.3594V25.6016C16.0352 27.5234 19.2812 28.4141 23.5 28.4141C27.7188 28.4141 30.9648 27.5234 33.5781 25.6016V30.3594C33.5781 33.5938 29.5117 35.8789 23.5 35.8789C17.4883 35.8789 13.4219 33.5938 13.4219 30.3594Z" fill="#3779E1"/>
    </SvgIcon>
);

export const ViewTypeIcon = (props) => (
    <SvgIcon width="12" height="13" viewBox="0 0 12 13" fill="none" {...props}>
        <path d="M6.00293 7.39568C6.23113 7.39568 6.45363 7.32151 6.72747 7.16178L11.1888 4.57172C11.6509 4.30358 11.8277 4.0925 11.8277 3.77873C11.8277 3.47066 11.6509 3.25957 11.1888 2.99144L6.72747 0.401384C6.45363 0.241645 6.23113 0.16748 6.00293 0.16748C5.76903 0.16748 5.55224 0.241645 5.2727 0.401384L0.81141 2.99144C0.349307 3.25957 0.172453 3.47066 0.172453 3.77873C0.172453 4.0925 0.349307 4.30358 0.81141 4.57172L5.2727 7.16178C5.55224 7.32151 5.76903 7.39568 6.00293 7.39568ZM6.00293 10.2082C6.20831 10.2082 6.38517 10.1112 6.62478 9.96862L11.5253 7.0762C11.7364 6.94499 11.8334 6.75672 11.8334 6.57987C11.8334 6.34596 11.6623 6.152 11.5025 6.07783L6.21972 9.12999C6.13415 9.17563 6.06569 9.20986 6.00293 9.20986C5.93447 9.20986 5.86601 9.17563 5.78044 9.12999L0.497636 6.07783C0.337897 6.152 0.166748 6.34596 0.166748 6.57987C0.166748 6.75672 0.269438 6.95069 0.480522 7.0762L5.37539 9.96862C5.6207 10.1112 5.79185 10.2082 6.00293 10.2082ZM6.00293 12.8325C6.20831 12.8325 6.38517 12.7355 6.62478 12.5929L11.5253 9.70049C11.7307 9.58068 11.8334 9.38101 11.8334 9.20415C11.8334 8.97025 11.6623 8.78199 11.5025 8.70212L6.21972 11.76C6.13415 11.8056 6.06569 11.8399 6.00293 11.8399C5.93447 11.8399 5.86601 11.8056 5.78044 11.76L0.497636 8.70212C0.337897 8.78199 0.166748 8.97025 0.166748 9.20415C0.166748 9.38101 0.269438 9.58068 0.480522 9.70049L5.37539 12.5929C5.6207 12.7355 5.79185 12.8325 6.00293 12.8325Z" fill="#435070"/>
    </SvgIcon>
);

export const LabelIcon = (props) => (
    <SvgIcon width="12" height="13" viewBox="0 0 12 13" fill="none" {...props}>
        <path d="M5.5 12.9395C8.52344 12.9395 10.5391 11.7969 10.5391 10.1797V3.08984C10.5391 1.61328 8.52344 0.59375 5.5 0.59375C2.47656 0.59375 0.460938 1.61328 0.460938 3.08984V10.1797C0.460938 11.7969 2.47656 12.9395 5.5 12.9395ZM5.5 4.69531C3.04492 4.69531 1.4043 4.0332 1.4043 3.08984C1.4043 2.10547 3.04492 1.41992 5.5 1.41992C7.95508 1.41992 9.5957 2.10547 9.5957 3.08984C9.5957 4.0332 7.95508 4.69531 5.5 4.69531ZM5.5 5.58008C7.28711 5.58008 8.72266 5.22852 9.5957 4.63086V7.29688C8.62891 7.92383 7.06445 8.33398 5.5 8.33398C3.93555 8.33398 2.37109 7.92383 1.4043 7.29688V4.63086C2.27734 5.22852 3.71289 5.58008 5.5 5.58008ZM5.5 9.21875C7.03516 9.21875 8.55273 8.86133 9.5957 8.29297V10.1797C9.5957 11.2637 7.95508 12.0547 5.5 12.0547C3.04492 12.0547 1.4043 11.2637 1.4043 10.1797V8.29297C2.44727 8.86133 3.95898 9.21875 5.5 9.21875Z" fill="#3779E1"/>
    </SvgIcon>
);